<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5" elevation="20">
        Développement
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- ajouter un menu pour mettre mes boutton dedans -->
      <v-menu offset-y max-width="250"
        v-if="isRights"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list min-width="250px">
          <v-list-item @click="openModalCreate">
            <v-list-item-title class="purple--text text--darken-3">Demande de développement</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="purple--text text--lighten-2">A voir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    
    </v-toolbar> 
    <!-- Layout partagé -->
    <v-container fluid class="d-flex"
      v-if="isRights">
      <v-card 
        width="20%"
        height="728"
        class="mr-2 elevation-2 mx-auto"
      >
        <!-- plannification -->
      
          <v-card
          height="280"
          >
            <v-app-bar
              dark
              color="primary"
              height="38"
            >
              <v-toolbar-title class="subtitle-1">Plannification</v-toolbar-title>
            </v-app-bar>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-card style="border: 1px solid rgb(129, 16, 129);"
                    rounded
                  >
                    <!-- formulaire de date debut et fin -->
                    <v-row class="pa-2">
                      <!-- choix du dev à attribuer -->
                      <v-col cols="12" >
                        <v-autocomplete class="autocomplete-max-height"
                          :items="users"
                          :item-text="item => `${item.firstname} ${item.lastname}`"
                          :label="'Développeur(s)'"
                          v-model="usersSelected"
                          outlined
                          hide-details
                          dense
                        >
                          <template slot="selection" slot-scope="data">
                            {{ data.item.firstname + ' ' + data.item.lastname }}
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ data.item.firstname + ' ' + data.item.lastname }}
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12"
                      >
                        <v-text-field class="pb-2"
                          label="Date de début"
                          type="datetime-local"
                          v-model="dateDebut"
                          outlined
                          dense
                          hide-details
                        ></v-text-field> 
                        <v-text-field
                          label="Date de fin"
                          v-model="dateFin"
                          type="datetime-local"
                          outlined
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                      <v-card-actions>
                        <v-btn
                        small
                        color="error"
                        dark
                        @click="resetAssignTask"
                        >
                        Annuler
                      </v-btn>
                      <v-spacer></v-spacer>
                        <v-btn
                          small
                          color="primary"
                          dark
                          @click="assignTask"
                          :class="{ 'disabled-button': !isValidAssignTask}"
                        >
                          Valider
                        </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        <v-card
          height="448"
          style="overflow: hidden;"
        >
        <!-- affiche les demandes dans des cartes -->
          <v-app-bar
            dark
            color="primary"
            height="38"
          >
            <v-toolbar-title class="subtitle-1">Liste des demandes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="openModalListeDemande"
            >
            <!-- liste des demandes assigné icon -->
              <v-icon>mdi-list-status</v-icon>
            </v-btn>
          </v-app-bar>
          <v-text-field
            v-model="search"
            label="Rechercher une demande"
            append-icon="mdi-magnify"
            outlined
            dense
            solo
            single-line
            hide-details
            class="pa-2"
          ></v-text-field>
          <vue-scroll
            style="height: 92%;"
          >
            <v-container class="pr-3"
            style="overflow: auto; height: 100%;"
          >
              <v-row dense>
                <v-alert
                  v-if="unAssignedTask.length === 0"
                  type="info"
                  border="left"
                  elevation="2"
                  dark
                  class="mx-auto"
                  style="width: 100%;"
                  prominent
                >
                  Aucune demande non assignée
                </v-alert>
                <v-col cols="12"
                  v-for="item in filteredUnAssignedTask"
                  :key="item.carte._id"
                  class="pb-2"
                >
                  <v-card style="border: 1px solid rgb(129, 16, 129);"
                    rounded
                    class="d-flex flex-column mr-1"
                  >
                  <!-- <div
                    style="background-color: #7b4e8e; height: 2rem; color: white; margin-bottom: 2px;"
                    :style="{ backgroundColor: getColorByDemande(item.carte.impactType) }"
                    class="d-flex align-center justify-space-between"
                  >
                    <div class="pl-2">
                      {{ item.carte.libelle }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn dark icon @click="openDetail(item._id)">
                      <v-icon size="20">mdi-information</v-icon>
                    </v-btn>
                    <v-checkbox
                      v-model="item.selected" 
                      dense
                      dark
                      @click="selectCard(item)"
                    ></v-checkbox> 
                  </div> -->
                  <!-- <v-card-text>
                    <v-row>
                      <v-icon class="mr-1" color="primary">mdi-account</v-icon>
                      {{ item.carte.userCreate && formatData(item.carte.userCreate) }}
                      <v-spacer></v-spacer>
                      <v-icon color="primary">mdi-calendar</v-icon>
                      {{ item.createdAt | formatDate }}
                    </v-row>
                  </v-card-text>

                    <v-divider class="mx-2"></v-divider>
                    <v-card-text>
                      <v-row>
                        <span class="black--text text--black darken-4 mr-1">Module: </span>
                        {{ item.carte.module && formatData(item.carte.module) }}
                      </v-row> 
                      <v-row>
                        <span class="black--text text--black darken-4 mr-1">Sous module: </span>
                        {{ item.carte.sous_module && formatData(item.carte.sous_module) }}
                      </v-row>
                      <v-row>
                        <span class="black--text text--black darken-4 mr-1">Type de développement: </span>
                        {{ item.carte.devType}}
                      </v-row>
                      <v-row>
                        <span class="black--text text--black darken-4 mr-1">Origine de la demande: </span>
                        {{ item.carte.originType }} 
                      </v-row>
                    </v-card-text> -->
                    <div class="card-header d-flex align-center justify-space-between" style="cursor: pointer;" :style="{ backgroundColor: getColorByDemande(item.carte.impactType) }">
                      <v-btn icon dark @click="toggleCardShow(item)">
                        <v-icon>{{ cardShow[item.carte._id] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <div class="white--text">
                        {{ item.carte.libelle }}
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn dark icon @click="openDetail(item._id)">
                        <v-icon size="20">mdi-information</v-icon>
                      </v-btn>
                      <v-checkbox
                        v-model="item.selected" 
                        dense
                        hide-details
                        class="ma-0 pa-0"
                        dark
                        @click="selectCard(item)"
                      >
                      </v-checkbox> 
                    </div>
                    <v-card-text v-if="cardShow[item.carte._id]">
                      <v-row dense align="center" class="mb-1">
                        <v-col cols="auto">
                          <v-avatar size="24" color="primary">
                            <v-icon dark small>mdi-account</v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col class="">
                          {{ formatData(item.carte.userCreate) }}
                        </v-col>
                        <v-col cols="auto">
                          <v-icon small color="grey">mdi-calendar</v-icon>
                          <span class="caption ml-1 ">{{ formatDate(item.createdAt) }}</span>
                        </v-col>
                      </v-row>

                      <v-row dense class="mb-1">
                        <v-col cols="6">
                          <v-chip x-small label outlined color="primary" class="">
                            {{ formatData(item.carte.module) }}
                          </v-chip>
                        </v-col>
                        <v-col cols="6">
                          <v-chip x-small label outlined color="secondary" class=""> 
                            {{ formatData(item.carte.sous_module) || 'Aucun' }}
                          </v-chip>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="6">
                          <v-icon small color="info" class="mr-1">mdi-cog</v-icon>
                          <span class="caption ">{{ item.carte.devType }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-icon small color="success" class="mr-1">mdi-source-branch</v-icon>
                          <span class="caption ">{{ item.carte.originType }}</span>
                        </v-col>
                      </v-row>

                      <v-row dense v-if="item.carte.assignement && item.carte.assignement.assignedTo">
                        <v-col cols="12">
                          <v-icon small color="warning" class="mr-1">mdi-account-check</v-icon>
                          <span class="caption ">Assigné à: {{ item.carte.assignement.assignedTo }}</span>
                        </v-col>
                      </v-row>

                      <v-row dense v-if="item.carte.duree">
                        <v-col cols="12">
                          <v-icon small color="error" class="mr-1">mdi-clock-outline</v-icon>
                          <span class="caption ">Durée: {{ item.carte.duree.duree }} {{ item.carte.duree.unite }}</span>
                        </v-col>
                      </v-row>

                      <v-row dense v-if="item.carte.datePrevisionnel">
                        <v-col cols="12">
                          <v-icon small color="purple" class="mr-1">mdi-calendar-check</v-icon>
                          <span class="caption ">Prévue: {{ formatDate(item.carte.datePrevisionnel) }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col> 
                
              </v-row>
            </v-container>
          </vue-scroll>
        </v-card>
      </v-card> 
        <!-- affiche calendar -->
      <v-col class="pa-0"
      > 
        <v-card
          class="mx-auto"
        >
          <v-app-bar
            dark
            color="primary"
            height="38"
          >
            <v-toolbar-title class="subtitle-1">Calendrier</v-toolbar-title>
          </v-app-bar>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <Calendar />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-container>
    <v-container fluid class="d-flex"
      v-else
    >
      <!-- afficher le calendar -->
      <calendar></calendar>
    </v-container>
    <!-- modale de creation -->
    <v-dialog v-model="createDev" max-width="50%">
      <CreateDev @close-create="createDev = false" @refresh="loadData"/>
    </v-dialog>

    <!-- modale de liste des demandes -->
    <v-dialog v-model="modalListeDemande" max-width="90%">
      <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Liste des demandes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalListeDemande = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- trier par impact desc -->
        <v-data-table
          :headers="headers"
          :items="taskSupportVision"
          :items-per-page="5"
          sort-by="carte.impactType"
          sort-desc
          class="elevation-1"
        > 
            <!-- si non assigné afficher non assigné -->
          <template  v-slot:item.carte.assignement.assignedTo="{ item }">
            {{ item.carte.assignement ? item.carte.assignement.assignedTo : 'Non assigné' }}
          </template>

          <!-- afficher la duree au bon format avec filtre -->
          <template v-slot:item.carte.assignement.duree="{ item }">
            <p v-if="item.carte.assignement && item.carte.assignement.duree">
              {{ item.carte.assignement.duree | formatDuration }}
            </p>
            <p v-else>
              Non assigné
            </p>
          </template>

          <!-- afficher dans v-chip de couleur les type d impact -->
          <template v-slot:item.carte.impactType="{ item }">
            <v-chip
              :color="getColorByDemande(item.carte.impactType)"
              dark
              small
            >
              {{ item.carte.impactType }}
            </v-chip>
          </template>

          <template v-slot:item.carte.assignement.status="{ item }">
            <v-chip v-if="item.carte.assignement && item.carte.assignement.status"
              :color="getColorByStatus(item.carte.assignement.status)"
              dark
              small
            >
              {{ item.carte.assignement.status  }}
            </v-chip>
            <v-chip v-else
              color="black"
              dark
              small
            >
              Aucun
            </v-chip>
          </template>
          
          

          <!-- si pas de module afficher aucun -->
          <!-- <template v-slot:item.carte.module="{ item }">
            {{ item.carte.module.length > 0 ? item.carte.module.join() : 'Aucun' }}
          </template> -->

          <!-- si pas de sous module afficher aucun -->
          <template v-slot:item.carte.sous_module="{ item }">
            {{ item.carte.sous_module && formatData(item.carte.sous_module) }}
          </template>

          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <!-- unassign task -->
              <v-btn v-if="item.carte.assignement && item.carte.assignement.assignedTo"
                icon
                small
                color="primary"
                dark
                @click="unassignTask(item._id)"
              >
                <v-icon>mdi-account-minus</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="warning"
                dark
                @click="openModalUpdate(item._id)"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="error"
                dark
                @click="openModalDelete(item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
              
          </template>

        </v-data-table>
      </v-card>
    </v-dialog>
    <!-- modale de modification -->
    <v-dialog v-model="modaleUpdate" max-width="50%">
      <EditDev @close-edit="modaleUpdate = false" @refresh="loadData"/>
    </v-dialog>
    <!-- modale de suppression -->
    <v-dialog v-model="modaleDelete" max-width="30%">
      <DeleteDev @close-delete-modal="modaleDelete = false" @dev-deleted="loadData"/> 
    </v-dialog>
    <!-- carte details -->
    <v-dialog v-model="modaleDetails" max-width="50%">
      <CarteDetails @close="modaleDetails = false" @refresh="loadData"/>
    </v-dialog>
  </v-container>  
</template>
<script>

import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import moment from 'moment';
import BasesService from '@/Services/SupportVision/BasesService'; 
import DeveloppementService from '@/Services/SupportVision/DeveloppementService';


export default {
  name: "Index",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    CreateDev: () => import("@/Components/Views/SupportVision/Cartes/createDev"),
    Calendar: () => import("@/Components/Views/SupportVision/Developpement/calendar"),
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
    DeleteDev: () => import("@/Components/Views/SupportVision/Developpement/devDelete"),
    CarteDetails: () => import("@/Components/Views/SupportVision/Cartes/carteDetails.vue"),
  },
  data() {
    return {
      createDev: false,
      cards: [],
      users: [],
      usersSelected: [],
      selected: false,
      dateDebut: '',
      dateFin: '',
      selectedEvent: {},
      modalListeDemande: false,
      headers: [
        { text: 'Libellé', value: 'carte.libelle', width: '15%'},
        { text: 'Assigné', value: 'carte.assignement.assignedTo', width: '15%'},
        { text: 'Durée', value: 'carte.assignement.duree', width: '15%'}, 
        { text: 'Impact', value: 'carte.impactType' },
        { text: 'Statut', value: 'carte.assignement.status', sortable: false, width: '15%'},
        { text: 'Type de développement', value: 'carte.devType', width: '10%'},
        { text: 'Origine', value: 'carte.originType', width: '10%'},
        { text: 'Module', value: 'carte.module', width: '10%'},
        { text: 'Sous module', value: 'carte.sous_module', width: '15%'},
        { text: 'Action', value: 'action', sortable: false, align: 'center', width: '10%'}
      ],
      modaleUpdate: false,
      modaleDelete: false,
      rights: [],
      modaleDetails: false,
      cardShow: {},
      search: ''
    }
  }, 
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    formatDuration: function (value) {
      if (value) {
        moment.locale('fr');
        return moment.duration(value).humanize()
      }
    }
  },
  computed: {
    ...Vuex.mapMutations(["setTaskSupportVision", "setTaskId", "setRightsDev"]),
    ...Vuex.mapState(["taskSupportVision", 'informations']),
    getColorByDemande() {
      return (demande) => {
        const colors = {
          'Travaux unique': '#43A047',
          'Release': '#1E88E5',
          'Urgent': '#E53935'
        };
        return colors[demande] || '#7b4e8e';
      }
    },
    getColorByStatus() {
      return (status) => {
        if (status == 'en_cours') {
          return 'orange';
        } else if (status == 'terminé') {
          return 'green';
        } else if (status == 'prise_en_compte') {
          return 'grey';
        } else if (status == undefined || status == '') {
          return 'black';
        }
      }
    },
    // retoune les taches non assigné
    unAssignedTask() {
      return this.cards.filter((item) => {
        return (item.carte.assignement == undefined  || item.carte.assignement.assigned == false) || !item.carte.assignement.assignedTo
      })
    },
    filteredUnAssignedTask() {
      return this.unAssignedTask.filter(item => {
        const searchTerm = this.search.toLowerCase();
        return item.carte.libelle.toLowerCase().includes(searchTerm) ||
               (item.carte.module && item.carte.module.toLowerCase().includes(searchTerm)) ||
               (item.carte.sous_module && item.carte.sous_module.toLowerCase().includes(searchTerm)) ||
               item.carte.devType.toLowerCase().includes(searchTerm) ||
               item.carte.originType.toLowerCase().includes(searchTerm) ||
               item.carte.impactType.toLowerCase().includes(searchTerm);
      });
    },
    isValidAssignTask() {
      return !!this.usersSelected.length && !!this.dateDebut && !!this.dateFin && !!this.selectedEvent._id
    },
    isRights() {
      let userMail = this.informations.emailAddress;  
      let callCenterMail = [];
      let sysAdminMail = [];
      let directionMail = [];
      let equipeTechniqueMail = [];
      this.rights.map((role) => {
        callCenterMail = role.params.callCenterMail;
        sysAdminMail = role.params.sysAdminMail;
        directionMail = role.params.directionMail;
        equipeTechniqueMail = role.params.equipeTechniqueMail;
      }) 
      let emailAuthor = sysAdminMail.includes(userMail) || directionMail.includes(userMail);
      // commit les droits dans le store
      this.$store.commit('setRightsDev', emailAuthor);
      return emailAuthor;
    }
  }, 
  methods: {
    openModalCreate() {
      this.createDev = true;
    },
    openModalUpdate(id) {
      this.$store.commit('setTaskId', id);
      this.modaleUpdate = true;
    },
    openDetail(id) {
      this.$store.commit('setTaskId', id);
      this.modaleDetails = true;
    },
    closeModaleUpdate() {
      this.modaleUpdate = false;
    },
    openModalDelete(id) {
      this.$store.commit('setTaskId', id);
      this.modaleDelete = true;
    },
    closeModaleDelete() {
      this.modaleDelete = false;
    },
    openModalListeDemande() {
      this.modalListeDemande = true;
    },
    resetAssignTask() {
      this.usersSelected = [];
      this.dateDebut = '';
      this.dateFin = '';
    },
    selectCard(item) {
      this.selectedEvent = item;
    },
    toggleCardShow(card) {
      this.$set(this.cardShow, card.carte._id, !this.cardShow[card.carte._id]);
    },
    formatData(data) {
      // si data est un tableau
      if (Array.isArray(data)) {
        return data.join();
      } else {
        return data && data.replace(/[\][\]"]+/g, '');
      }
    },
    async assignTask() { 
      const data = {
        assignement: {
          dateAssignement: moment().format('YYYY-MM-DD HH:mm:ss'),
          assignedTo: this.usersSelected,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
          event: this.selectedEvent,
          id: this.selectedEvent._id,
          assigned: true
        }
      }
      const id = this.selectedEvent._id;
      const res = await DeveloppementService.assignTask(id, data)
      this.resetAssignTask();
      this.loadData();
      this.$nInfo('Tâche assignée avec succès');
    },
    async unassignTask(id) {
      const res = await DeveloppementService.unassignTask(id)
      this.loadData();
      this.$nInfo('Tâche désassignée avec succès');
    },
    async loadData() {
     const res = await DeveloppementService.getDev()
     this.$store.commit('setTaskSupportVision', res.data);
     this.cards = this.$store.state.taskSupportVision;
      await UserService.getUsers().then((res) => {
        this.users = res;
      });
      this.rights = await BasesService.getParams();
    }, 
  },
  async mounted() { 
    await this.loadData(); 
  }
}
</script>

<style scoped> 
.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}
.autocomplete-max-height {
  max-height: 50px;  
  overflow-y: auto;  
}
.biais-trait {
  position: relative;
}

.biais-trait::after {
  content: '';
  position: absolute;
  bottom: 5px;
  right: 13px; /* Ajustez la position du trait */
  width: 2px; /* Longueur du trait */
  height: 20px;
  background-color: gray; /* Couleur du trait */
  transform: skewX(-50deg); /* Inclinaison du trait */
}
.biais-trait::before {
  content: '';
  position: absolute;
  bottom: 3px;
  right: 10px; 
  width: 2px; 
  height: 14px;
  background-color: gray; 
  transform: skewX(-50deg); 
}

</style>